'use client';

import { textVariant } from '@/src/lib/motion';
import MotionWrapper from '@/src/lib/motionWrapper';
import { cn } from '@/src/lib/utils';
import { syllabusData } from '@/src/shared/data';
import { m } from 'framer-motion';
import Image from 'next/image';
import { TypographyH3 } from '../typography/TypographyH3';
import { TypographyParagraph } from '../typography/TypographyParagraph';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
	AccordionTriggerButton,
} from '../ui/accordion';
import boltIcon from '/public/vectors/skill-bolt-icon.svg';

export default function SyllabusContent() {
	return (
		<MotionWrapper>
			<Accordion type="single" collapsible className="w-full">
				{syllabusData.map((skillset, index) => (
					<m.div
						variants={textVariant(0.3)}
						initial="hidden"
						whileInView="show"
						viewport={{ once: true, amount: 0.25 }}
						key={`skillset-${index}`}
					>
						<AccordionItem
							value={String(index)}
							className={cn(
								'border-divider bg-black/30 px-4 py-2',
								itemFormatter(index)
							)}
						>
							<AccordionTrigger className="">
								<div className="flex items-center">
									<div className="mr-2 shrink-0 md:mr-4">
										<Image
											src={boltIcon}
											alt="Bolt icon"
											aria-hidden="true"
											className="size-6 sm:size-8"
										/>
									</div>
									<TypographyH3 className="italic-mild cursive-off mb-0 text-xl font-extrabold uppercase tracking-normal text-white/95 transition-all">
										{skillset.title}
									</TypographyH3>
								</div>
								<AccordionTriggerButton
									buttonClassName="hover:border-white/30 group-hover:bg-white/10"
									iconColor="white"
								/>
							</AccordionTrigger>
							<AccordionContent className="pl-3 xl:pl-12">
								<div className="mt-3 flex w-full flex-col gap-x-8 gap-y-4 md:flex-row">
									<TypographyParagraph className="casual-30 cursive-off text-pretty pr-3 text-left text-gray-400 md:w-2/5">
										{skillset.description}
									</TypographyParagraph>
									<div className="flex flex-wrap items-start gap-2 text-left md:w-3/5">
										{skillset.skills.map((skill, index) => {
											if (skill.traditionalName != null) {
												return (
													<div key={skill.commonName}>
														<CommonAndTraditionalNameSkill
															commonName={
																skill.commonName
															}
															traditionalName={
																skill.traditionalName
															}
															index={index}
														/>
													</div>
												);
											} else {
												return (
													<div key={skill.commonName}>
														<CommonNameSkill
															commonName={
																skill.commonName
															}
															index={index}
														/>
													</div>
												);
											}
										})}
									</div>
								</div>
							</AccordionContent>
						</AccordionItem>
					</m.div>
				))}
			</Accordion>
		</MotionWrapper>
	);
}

const itemFormatter = (index: number): string => {
	if (index === 0) {
		return 'border-b rounded-tl-md rounded-tr-md';
	} else if (index === 4) {
		return 'border-b-0 rounded-bl-md rounded-br-md';
	} else {
		return 'border-b';
	}
};

const CommonNameSkill = ({ commonName }: any) => {
	return (
		<span className="mono-100 cursive-off inline-flex items-center rounded-md bg-yellow-primary px-2 py-1 text-sm font-medium text-black/90 ">
			{commonName}
		</span>
	);
};

const CommonAndTraditionalNameSkill = ({
	commonName,
	traditionalName,
}: any) => {
	return (
		<div className="grid grid-cols-1">
			<span className="mono-100 cursive-off inline-flex items-center rounded-t-md bg-yellow-primary px-2 py-1 text-sm font-medium text-black/90">
				{commonName}
			</span>
			<span className="mono-100 casual-100 italic-mild cursive-off inline-flex items-center rounded-b-md bg-yellow-secondary px-2 py-1 text-sm font-medium text-black/90 ">
				{traditionalName}
			</span>
		</div>
	);
};
