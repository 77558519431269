'use client';

import { EmblaOptionsType, EmblaPluginType } from 'embla-carousel';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import { m } from 'framer-motion';
import Image, { StaticImageData } from 'next/image';
import React from 'react';

import { textVariant } from '@/src/lib/motion';
import { cn } from '@/src/lib/utils';

import MotionWrapper from '@/src/lib/motionWrapper';
import { DotButton, useDotButton } from './embla/EmblaCarouselDotButton';

export interface carouselImage {
	image: StaticImageData;
	altText: string;
}

type PropType = {
	carounselImages: carouselImage[];
	priority: boolean;
};

const ImagesCarousel: React.FC<PropType> = (props) => {
	const { carounselImages, priority } = props;

	const options: EmblaOptionsType = { loop: true };
	const plugins: EmblaPluginType[] = [
		Autoplay({ delay: 4000, stopOnInteraction: false }),
	];

	const [emblaRef, emblaApi] = useEmblaCarousel(options, plugins);

	const { selectedIndex, scrollSnaps, onDotButtonClick } =
		useDotButton(emblaApi);

	return (
		<MotionWrapper>
			<m.section
				variants={textVariant(0.3)}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0 }}
				className="m-auto max-w-3xl"
			>
				<div className="overflow-hidden rounded-lg" ref={emblaRef}>
					<div className="-ml-4 flex touch-pan-y">
						{carounselImages.map((carouselImage, index) => (
							<div
								className="flex shrink-0 grow-0 basis-full pl-4 hover:cursor-grab"
								key={index}
							>
								<Image
									className="flex aspect-video h-full w-auto items-center justify-center rounded-lg object-cover"
									width={1080}
									height={720}
									sizes="94.33vw"
									src={carouselImage.image}
									alt={carouselImage.altText}
									priority={priority}
								/>
							</div>
						))}
					</div>
				</div>
				<div className="mt-6 grid grid-cols-1 justify-between gap-5">
					<div className="flex flex-wrap items-center justify-center gap-4">
						{scrollSnaps.map((_, index) => (
							<DotButton
								key={index}
								onClick={() => onDotButtonClick(index)}
								className={cn(
									"inline-flex h-5 w-5 cursor-pointer touch-manipulation appearance-none items-center justify-center rounded-full border-0 bg-transparent p-0 no-underline after:h-5 after:w-5 after:rounded-full after:bg-black/10 after:content-['']",
									index === selectedIndex
										? ' bg-black/30'
										: ''
								)}
							/>
						))}
					</div>
				</div>
			</m.section>
		</MotionWrapper>
	);
};

export default ImagesCarousel;
