'use client';

import { textVariant } from '@/src/lib/motion';
import { m } from 'framer-motion';
import Image from 'next/image';
import boltIcon from '/public/vectors/faq-bolt-icon.svg';

import MotionWrapper from '@/src/lib/motionWrapper';
import { TypographyH3 } from '../typography/TypographyH3';
import { TypographyParagraph } from '../typography/TypographyParagraph';

const Feature = ({ title, body, index }) => {
	return (
		<MotionWrapper>
			<m.div
				variants={textVariant(0.2)}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0 }}
				className="flex"
			>
				<Image
					src={boltIcon}
					alt="Bolt icon"
					aria-hidden="true"
					className="mr-2 size-6 shrink-0 sm:size-8 md:mr-4"
				/>
				<div className="mt-[-3px] flex flex-col gap-y-1 sm:mt-0 md:gap-y-3">
					<TypographyH3 className="">{title}</TypographyH3>
					<TypographyParagraph className="">
						{body}
					</TypographyParagraph>
				</div>
			</m.div>
		</MotionWrapper>
	);
};

export default Feature;
