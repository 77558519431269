import { cn } from '@/src/lib/utils';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import Image from 'next/image';
import * as React from 'react';
import plusBlackIcon from '/public/vectors/plus-black-icon.svg';
import plusWhiteIcon from '/public/vectors/plus-white-icon.svg';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
	React.ElementRef<typeof AccordionPrimitive.Item>,
	React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
	<AccordionPrimitive.Item
		ref={ref}
		className={cn('border-b', className)}
		{...props}
	/>
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
	React.ElementRef<typeof AccordionPrimitive.Trigger>,
	React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
	<AccordionPrimitive.Header className="flex">
		<AccordionPrimitive.Trigger
			ref={ref}
			className={cn(
				'group flex flex-1 items-center justify-between py-4 text-left font-medium transition-all',
				className
			)}
			{...props}
		>
			{children}
			{/* Pass in an <AccordianTriggerButton /> from the parent */}
		</AccordionPrimitive.Trigger>
	</AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
	React.ElementRef<typeof AccordionPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
	<AccordionPrimitive.Content
		ref={ref}
		className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
		{...props}
	>
		<div className={cn('pb-4 pt-0', className)}>{children}</div>
	</AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

const AccordionTriggerButton = ({ buttonClassName, iconColor }) => {
	return (
		<span
			className={cn(
				'flex shrink-0 rounded-full border border-transparent p-2 transition-colors duration-300 ease-out hover:border-black/30 group-hover:bg-black/10',
				buttonClassName
			)}
		>
			{iconColor === 'black' && (
				<Image
					src={plusBlackIcon}
					alt="Plus icon"
					aria-hidden="true"
					className="flex size-7 shrink-0 transition-[rotate] duration-300 [[data-state=open]_&]:[rotate:135deg]"
				/>
			)}

			{iconColor === 'white' && (
				<Image
					src={plusWhiteIcon}
					alt="Plus icon"
					aria-hidden="true"
					className="flex size-7 shrink-0 transition-[rotate] duration-300 [[data-state=open]_&]:[rotate:135deg]"
				/>
			)}
		</span>
	);
};

export {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
	AccordionTriggerButton,
};
