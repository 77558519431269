import { cn } from '@/src/lib/utils';

export function TypographyH3({ children, className }) {
	return (
		<h3
			className={cn(
				'text-xl font-extrabold tracking-tight text-black md:text-2xl',
				className
			)}
		>
			{children}
		</h3>
	);
}
