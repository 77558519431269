'use client';

import collartie from '@/public/about/landscape/men-collar-tie.webp';
import groundfight from '../../../public/about/landscape/men-ground-fight.webp';
import wrestling from '../../../public/about/landscape/men-wrestling.webp';
import womanbagwork from '../../../public/about/landscape/woman-bagwork.webp';
import womenbagwork from '../../../public/about/landscape/women-bagwork.webp';
import ImagesCarousel, { carouselImage } from './ImagesCarousel';

const AboutCarousel = () => {
	const carouselImages: carouselImage[] = [
		{
			image: wrestling,
			altText: 'Wrestling',
		},
		{
			image: womenbagwork,
			altText: 'Bag work',
		},
		{
			image: collartie,
			altText: 'Collar tie',
		},
		{
			image: womanbagwork,
			altText: 'Bag work',
		},
		{
			image: groundfight,
			altText: 'Ground fighting',
		},
	];

	return <ImagesCarousel carounselImages={carouselImages} priority={false} />;
};

export default AboutCarousel;
